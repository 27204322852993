<template>
  <b-card-body>
    <b-row>
      <b-col
        v-for="(item,index) in interviewCounters"
        v-if="item.value"
        :key="index"
      >
        <item-statistics
          :title="item.title"
          :icon="item.icon"
          :color="item.color"
          :value="item.value"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import { BCardBody, BRow, BCol } from 'bootstrap-vue'
import ItemStatistics from '@/views/Interviews/components/ItemStatistics.vue'

export default {
  name: 'Counters',
  components: {
    BCardBody, BRow, BCol, ItemStatistics,
  },
  computed: {
    interviewCounters() {
      return this.$store.getters['interviews/getInterviewCounters']
    },
  },
}
</script>
