<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Başlangıç Tarihi"
        label-for="sdate"
      >
        <b-form-datepicker
          id="sdate"
          v-model="filterData.sdate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Bitiş Tarihi"
        label-for="edate"
      >
        <b-form-datepicker
          id="edate"
          v-model="filterData.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'Dates',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
  },
}
</script>

<style scoped>

</style>
