<template>
  <b-form-group
    label="Danışman"
    label-for="id_com_user"
  >
    <v-select
      id="id_com_user"
      v-model="filterData.id_com_user"
      placeholder="Danışman"
      :options="users"
      label="title"
      :reduce="user => user.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Users',
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    customWhere: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      const params = {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
        ],
        order_by: ['com_user.name', 'ASC'],
      }
      if (this.customWhere) {
        params.where = this.customWhere
      }
      this.$store.dispatch('users/usersList', params)
    },
  },
}
</script>

<style scoped>

</style>
