<template>
  <b-form-group
    label="Marka"
    label-for="id_com_brand"
  >
    <v-select
      id="id_com_brand"
      v-model="filterData.id_com_brand"
      placeholder="Marka"
      :options="brands"
      label="title"
      :reduce="brand => brand.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    isSalesBrand: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    getBrands() {
      const params = {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {},
        order_by: ['com_brand.ordering', 'ASC'],
      }
      if (this.isSalesBrand === true) {
        params.where = {
          'com_brand.salestatus': 1,
        }
      }
      this.$store.dispatch('brands/brandsList', params)
    },
  },
}
</script>

<style scoped>

</style>
