<template>
  <b-form-group
    label="Konu"
    label-for="id_com_interview_subject"
  >
    <v-select
      id="id_com_interview_subject"
      v-model="filterData.id_com_interview_subject"
      placeholder="Konu"
      :options="subjects"
      label="title"
      :reduce="subject => subject.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    interviewTypeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    subjects() {
      return this.$store.getters['interviewSubjects/getInterview_subjects']
    },
  },
  created() {
    this.getSubjects()
  },
  methods: {
    getSubjects() {
      const params = {
        select: [
          'com_interview_subject.id AS id',
          'com_interview_subject.title AS title',
        ],
        where: {
          'com_interview_subject.id_com_interview_type': this.interviewTypeId,
        },
      }
      this.$store.dispatch('interviewSubjects/interview_subjectsList', params)
    },
  },
}
</script>
