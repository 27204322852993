<template>
  <b-form-group
    label="Hızlı Arama"
    label-for="keyword"
  >
    <b-input-group>
      <b-form-input
        id="keyword"
        v-model="quickSearch.keyword"
        placeholder="Müşteri Adı, Telefon, Görüşme No..."
        @keydown.enter="searchData"
      />
      <b-input-group-append>
        <b-button
          v-if="quickSearch.keyword"
          variant="warning"
          @click="resetFilter"
        >
          <FeatherIcon icon="XCircleIcon" />
        </b-button>
        <b-button
          variant="primary"
          @click="searchData"
        ><FeatherIcon icon="SearchIcon" /></b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'QuickSearch',
  components: {
    BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
    resetFilter: {
      type: Function,
      required: true,
    },
  },
  computed: {
    quickSearch() {
      return this.$store.getters['interviews/getQuickSearch']
    },
  },
}
</script>

<style scoped>

</style>
