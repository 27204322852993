<template>
  <b-form-group
    label="Temas Tipi"
    label-for="id_com_meet"
  >
    <v-select
      id="id_com_meet"
      v-model="filterData.id_com_meet"
      placeholder="Temas Tipi"
      :options="meets"
      label="title"
      :reduce="meet => meet.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Meets',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
  },
  created() {
    this.getMeets()
  },
  methods: {
    getMeets() {
      this.$store.dispatch('meets/meetsList')
    },
  },
}
</script>

<style scoped>

</style>
